@import 'src/style/color';
.self {
  position: relative;
}

.button {
  cursor: pointer;
}

.light {
  border-radius: 10px;
  border: none;
  color: white;
  background-color: var(--button-light);

  &:disabled {
    cursor: default;
    background-color: var(--button-light-disabled);
  }

  &:hover:enabled {
    background-color: var(--button-light-hover);
  }

  &:active:enabled {
    background-color: var(--button-light-active);
  }
}

.dark {
  border-radius: 10px;
  border: none;
  color: white;
  background-color: var(--button-dark);

  &:disabled {
    cursor: default;
    background-color: var(--button-dark-disabled);
  }

  &:hover:enabled {
    background-color: var(--button-dark-hover);
  }

  &:active:enabled {
    background-color: var(--button-dark-active);
  }
}

.outlined {
  border-radius: 10px;
  border: 2px solid var(--button-dark);
  color: var(--button-dark);
  background-color: white;

  &:disabled {
    cursor: default;
    border: 2px solid var(--button-dark-disabled);
    color: var(--button-dark-disabled);
    background-color: white;
  }

  &:hover:enabled {
    border: 2px solid var(--button-dark-hover);
    color: white;
    background-color: var(--button-dark-hover);
  }

  &:active:enabled {
    border: 2px solid var(--button-dark-active);
    color: white;
    background-color: var(--button-dark-active);
  }
}

.text {
  border: none;
  color: var(--button-light);
  text-decoration: none;
  background-color: transparent;

  &:disabled {
    cursor: none;
    color: var(--button-light-disabled);
    text-decoration: none;
  }

  &:hover:enabled {
    color: var(--button-light-hover);
    text-decoration: underline;
    text-decoration-color: var(--button-light-hover);
  }

  &:active:enabled {
    color: var(--button-light-active);
    text-decoration: none;
  }
}

.large {
  margin-top: 0px;
  min-height: 48px;
  min-width: 133px;
  font-size: 16px;
  padding: 12px 40px;
}

.medium {
  margin-top: 0px;
  min-height: 40px;
  min-width: 106px;
  font-size: 14px;
  padding: 9px 30px;
}

.small {
  margin-top: 0px;
  min-height: 30px;
  min-width: 43px;
  font-size: 13px;
  padding: 6px 20px;
}

.invisible {
  visibility: hidden;
}

.progressWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
}

.progress {
  width: 24px !important;
  height: 24px !important;
  color: var(--button-progress) !important;
}
