@import 'src/style/variable';
@import 'src/style/mixin';
.form {
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.button {
  cursor: pointer;
  color: var(--menu_button-light);
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
  text-decoration: none;
}

.buttonLayout {
  display: flex;
  justify-content: flex-end;
}
