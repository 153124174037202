@tailwind base;
@tailwind components;
@tailwind utilities;

/* disable spin button of input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

:root {
  font-family: 'Prompt', 'Noto Sans CJK TC', 'Noto Sans CJK SC', sans-serif;
  font-size: 16px;
  color: #011833;
  background: #fbfcfe;
}
