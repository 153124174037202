.input {
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  padding: 0 8px;
  border: solid 1px var(--input-background);
  background-color: var(--input-background);
  color: var(--input-foreground);
  font-size: 16px;

  &:focus {
    outline: none;
    border: solid 1px var(--input-border);
  }
}

.error {
  outline: none;
  border: 1px solid var(--input-error);
}

.disabled {
  background-color: var(--input-background-disabled);
  color: var(--input-foreground-disabled);
}
