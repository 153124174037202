@import './variable';

.react-calendar {
  box-shadow: 0 15px 20px 0;
  width: 316px;
  z-index: 10;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--input-calendar-background);
}

.react-calendar__navigation {
  height: 40px;
  padding: 4px 4px 0px 4px;
  color: var(--input-calendar-nav-foreground);
  background-color: var(--input-calendar-nav-background);
}

.react-calendar__navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.react-calendar__navigation__arrow {
  width: 44px;
  background-color: initial;
  color: inherit;
}

.react-calendar__navigation__label {
  width: 132px;
  background-color: initial;
  color: inherit;
}

.react-calendar__viewContainer {
  padding: 4px;
}

.react-calendar__month-view__weekdays {
  height: 40px;
  align-items: center;
  text-align: center;
  font-size: 14px;
  color: var(--input-calendar-weekdays);
}

abbr {
  text-decoration: none;
}

.react-calendar__tile {
  border-radius: 4px;
  color: var(--input-calendar-foreground);
  background-color: var(--input-calendar-background);

  &:hover {
    color: var(--input-calendar-hover-foreground);
    background-color: var(--input-calendar-hover-background);
  }
}

.react-calendar__tile--active {
  color: var(--input-calendar-selected-foreground) !important;
  background-color: var(--input-calendar-selected-background) !important;
}

.react-calendar__month-view__days__day {
  height: 40px;
}

.react-calendar__tile--now {
  color: var(--input-calendar-current-foreground);
  background-color: var(--input-calendar-current-background);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--input-calendar-unavailable);
}

.react-calendar__year-view__months__month {
  height: 50px;
}

.react-calendar__decade-view__years__year {
  height: 50px;
}

.react-calendar__century-view__decades__decade {
  height: 50px;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
