@import './color';

html[data-theme='light'] {
  --button-light: #{$primary-500};
  --button-light-active: #{$primary-700};
  --button-light-hover: #{$primary-600};
  --button-light-disabled: #{$primary-30};
  --button-dark: #{$black-700};
  --button-dark-active: #{$grey-700};
  --button-dark-hover: #{$black-500};
  --button-dark-disabled: #{$grey-300};
  --button-progress: #{$primary-500};

  --switch-track: #{$primary-500};
  --switch-primary: #{$primary-500};
  --switch-primary-disabled: #{$primary-disabled};
  --switch-ripple: #{$primary-ripple};

  --navbar-background: #{$white};
  --navbar-foreground: #{$black-700};

  --popover-background: #{$white};
  --popover-divider: #{$light-200};
  --popover-item-hover: #{$light-150};
  --popover-item: #{$black-700};
  --popover-item-heading: #{$grey-500};

  --merchant-modal-active: #{$light-200};

  --background: #{$white};
  --title-grey: #{$grey-700};
  --heading-text-color: #{$black-700};
  --body-text-color: #{$black-700};
  --divider: #{$light-300};

  --page-background: #{$light-100};
  --page-fee-table-background: #{$white};
  --page-fee-foreground-dark: #{$black-700};
  --page-fee-foreground-light: #{$grey-700};
  --drawer-background: #{$white};
  --drawer-foreground: #{$black-700};
  --drawer-item-heading: #{$grey-500};
  --drawer-item-active: #{$light-200};

  --menu-background: #{$white};
  --menu_shadow: #{$black-710};
  --menu_button-hover: #{$light-150};
  --menu_button-light: #{$primary-500};

  --footer-background: #{$black-600};
  --footer-button-color: #{$white};
  --footer-copyright-color: #{$grey-300};

  --page-contact-us-background: #{$white};

  --input-foreground: #{$black-700};
  --input-border: #{$primary-500};
  --input-background: #{$light-200};
  --input-error: #{$alert};
  --input-help: #{$grey-700};
  --input-background-disabled: #{$light-150};
  --input-foreground-disabled: #{$grey-300};

  --input-calendar-nav-background: #{$black-500};
  --input-calendar-nav-foreground: #{$white};
  --input-calendar-background: #{$white};
  --input-calendar-foreground: #{$black-700};
  --input-calendar-unavailable: #{$grey-300};
  --input-calendar-weekdays: #{$grey-500};
  --input-calendar-selected-background: #{$primary-500};
  --input-calendar-selected-foreground: #{$white};
  --input-calendar-current-background: #{$grey-500};
  --input-calendar-current-foreground: #{$white};
  --input-calendar-hover-background: #{$light-300};
  --input-calendar-hover-foreground: #{$black-700};

  --snackbar-success: #{$primary-600};
  --snackbar-info: #{$info};
  --snackbar-warning: #{$warning};
  --snackbar-error: #{$alert};
  --snackbar-text: #{$white};
  --snackbar-text-warning: #{$black-500};

  --modal-background: #{$white};

  --alert-success: #{$primary-200};
  --alert-info: #{$info-200};
  --alert-warning: #{$warning-200};
  --alert-error: #{$alert-200};
  --alert-text: #{$black-500};
  --alert-text-error: #{$alert};

  --enableTwoFa-content-background: #{$light-150};
  --enableTwoFa-bottom-background: #{$black-500};
  --enableTwoFa-bottom-foreground: #{$white};

  --pagination-page-number-foreground: #{$black-700};
  --pagination-page-number-background: #{$light-200};
  --pagination-page-count-foreground: #{$grey-700};

  --dashboard-order-notice-background: #{$light-300};
  --dashboard-order-notice-foreground: #{$grey-700};
  --dashboard-order-noRecord: #{$grey-500};
  --dashboard-order-filter-title-text: #{$black-700};
  --dashboard-order-filter-background: #{$light-200};
  --dashboard-order-card-background: #{$white};
  --dashboard-order-card-detail-background: #{$light-150};
  --dashboard-order-card-item-title-text: #{$grey-500};
  --dashboard-order-card-item-value-text: #{$grey-700};
  --dashboard-order-table-item-title-text: #{$grey-700};
  --dashboard-order-table-item-value-text: #{$black-700};
  --dashboard-order-table-divider-background: #{$light-300};
  --dashboard-order-detail-text: #{$primary-500};

  --dashboard-order-detail-value-text: #{$grey-700};
  --dashboard-order-detail-title-text: #{$grey-500};

  --dashboard-balance-item-title: #{$grey-500};
  --dashboard-balance-item-value: #{$grey-700};
  --dashboard-balance-divider: #{$grey-300};
  --dashboard-balance-icon-hover: #{$light-300};

  --create-merchant-button: #{$grey-300};
  --create-merchant-button-text: #{$grey-500};

  --setting-pre-button: #{$primary-500};
  --setting-box-background: #{$white};
  --setting-dark-box-background: #{$light-200};
  --setting-divider: #{$grey-300};
  --setting-item-title: #{$grey-500};
  --setting-item-content: #{$grey-700};
  --setting-helper: #{$grey-700};
}
