@import 'src/style/variable';

.self {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: var(--input-foreground);
}

.disabledMessage {
  color: var(--input-foreground-disabled);
}

.errorMessage {
  font-size: 12px;
  color: var(--input-error);
}

.help {
  font-size: 12px;
  color: var(--input-help);
}

.label {
  display: flex;
  flex-direction: row;
}

.labelAsterisk {
  color: var(--input-error);
}
